import { default as cookies0unP4xAiXwMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue?macro=true";
import { default as cookiesUSjOeMHbAWMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue?macro=true";
import { default as indexMJ9PQwXSF2Meta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue?macro=true";
import { default as legal68PtcJQuujMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue?macro=true";
import { default as portfolio_45about1KI7e0sgqQMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue?macro=true";
import { default as portfolio_45feesTE9vXQampCMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsfVZq6FhrOWMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuersbn3eZJw1yzMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue?macro=true";
import { default as portfolio_45memberskWCcf3G4i1Meta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue?macro=true";
import { default as indexrHajlDEg3aMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersBaKrRC9LtDMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue?macro=true";
import { default as privacyd7NFLCfsoPMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue?macro=true";
import { default as indexFPqfMO3CmNMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue?macro=true";
import { default as legalgAKhoyUjucMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue?macro=true";
import { default as portfolio_45aboutROKueoh8LgMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue?macro=true";
import { default as portfolio_45fees16m8y1Rhu8Meta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue?macro=true";
import { default as portfolio_45investorsLpUaAPOHVuMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue?macro=true";
import { default as portfolio_45issuerseNzbFaRcf4Meta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue?macro=true";
import { default as portfolio_45membersfRuKjMuPKVMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue?macro=true";
import { default as indexDR4GABt9wpMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue?macro=true";
import { default as transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue?macro=true";
import { default as portfolio_45partnersqDGmtoktaLMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue?macro=true";
import { default as privacy12YVewrbhgMeta } from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue?macro=true";
export default [
  {
    name: cookies0unP4xAiXwMeta?.name ?? "cookies",
    path: cookies0unP4xAiXwMeta?.path ?? "/cookies",
    meta: cookies0unP4xAiXwMeta || {},
    alias: cookies0unP4xAiXwMeta?.alias || [],
    redirect: cookies0unP4xAiXwMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookiesUSjOeMHbAWMeta?.name ?? "es-cookies",
    path: cookiesUSjOeMHbAWMeta?.path ?? "/es/cookies",
    meta: cookiesUSjOeMHbAWMeta || {},
    alias: cookiesUSjOeMHbAWMeta?.alias || [],
    redirect: cookiesUSjOeMHbAWMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexMJ9PQwXSF2Meta?.name ?? "es",
    path: indexMJ9PQwXSF2Meta?.path ?? "/es",
    meta: indexMJ9PQwXSF2Meta || {},
    alias: indexMJ9PQwXSF2Meta?.alias || [],
    redirect: indexMJ9PQwXSF2Meta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: legal68PtcJQuujMeta?.name ?? "es-legal",
    path: legal68PtcJQuujMeta?.path ?? "/es/legal",
    meta: legal68PtcJQuujMeta || {},
    alias: legal68PtcJQuujMeta?.alias || [],
    redirect: legal68PtcJQuujMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45about1KI7e0sgqQMeta?.name ?? "es-portfolio-about",
    path: portfolio_45about1KI7e0sgqQMeta?.path ?? "/es/portfolio-about",
    meta: portfolio_45about1KI7e0sgqQMeta || {},
    alias: portfolio_45about1KI7e0sgqQMeta?.alias || [],
    redirect: portfolio_45about1KI7e0sgqQMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45feesTE9vXQampCMeta?.name ?? "es-portfolio-fees",
    path: portfolio_45feesTE9vXQampCMeta?.path ?? "/es/portfolio-fees",
    meta: portfolio_45feesTE9vXQampCMeta || {},
    alias: portfolio_45feesTE9vXQampCMeta?.alias || [],
    redirect: portfolio_45feesTE9vXQampCMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsfVZq6FhrOWMeta?.name ?? "es-portfolio-investors",
    path: portfolio_45investorsfVZq6FhrOWMeta?.path ?? "/es/portfolio-investors",
    meta: portfolio_45investorsfVZq6FhrOWMeta || {},
    alias: portfolio_45investorsfVZq6FhrOWMeta?.alias || [],
    redirect: portfolio_45investorsfVZq6FhrOWMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuersbn3eZJw1yzMeta?.name ?? "es-portfolio-issuers",
    path: portfolio_45issuersbn3eZJw1yzMeta?.path ?? "/es/portfolio-issuers",
    meta: portfolio_45issuersbn3eZJw1yzMeta || {},
    alias: portfolio_45issuersbn3eZJw1yzMeta?.alias || [],
    redirect: portfolio_45issuersbn3eZJw1yzMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45memberskWCcf3G4i1Meta?.name ?? "es-portfolio-members",
    path: portfolio_45memberskWCcf3G4i1Meta?.path ?? "/es/portfolio-members",
    meta: portfolio_45memberskWCcf3G4i1Meta || {},
    alias: portfolio_45memberskWCcf3G4i1Meta?.alias || [],
    redirect: portfolio_45memberskWCcf3G4i1Meta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexrHajlDEg3aMeta?.name ?? "es-portfolio-news",
    path: indexrHajlDEg3aMeta?.path ?? "/es/portfolio-news",
    meta: indexrHajlDEg3aMeta || {},
    alias: indexrHajlDEg3aMeta?.alias || [],
    redirect: indexrHajlDEg3aMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta?.name ?? "es-portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta?.path ?? "/es/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitYNdxn6clBSMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersBaKrRC9LtDMeta?.name ?? "es-portfolio-partners",
    path: portfolio_45partnersBaKrRC9LtDMeta?.path ?? "/es/portfolio-partners",
    meta: portfolio_45partnersBaKrRC9LtDMeta || {},
    alias: portfolio_45partnersBaKrRC9LtDMeta?.alias || [],
    redirect: portfolio_45partnersBaKrRC9LtDMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacyd7NFLCfsoPMeta?.name ?? "es-privacy",
    path: privacyd7NFLCfsoPMeta?.path ?? "/es/privacy",
    meta: privacyd7NFLCfsoPMeta || {},
    alias: privacyd7NFLCfsoPMeta?.alias || [],
    redirect: privacyd7NFLCfsoPMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/es/privacy.vue").then(m => m.default || m)
  },
  {
    name: indexFPqfMO3CmNMeta?.name ?? "index",
    path: indexFPqfMO3CmNMeta?.path ?? "/",
    meta: indexFPqfMO3CmNMeta || {},
    alias: indexFPqfMO3CmNMeta?.alias || [],
    redirect: indexFPqfMO3CmNMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legalgAKhoyUjucMeta?.name ?? "legal",
    path: legalgAKhoyUjucMeta?.path ?? "/legal",
    meta: legalgAKhoyUjucMeta || {},
    alias: legalgAKhoyUjucMeta?.alias || [],
    redirect: legalgAKhoyUjucMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45aboutROKueoh8LgMeta?.name ?? "portfolio-about",
    path: portfolio_45aboutROKueoh8LgMeta?.path ?? "/portfolio-about",
    meta: portfolio_45aboutROKueoh8LgMeta || {},
    alias: portfolio_45aboutROKueoh8LgMeta?.alias || [],
    redirect: portfolio_45aboutROKueoh8LgMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-about.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45fees16m8y1Rhu8Meta?.name ?? "portfolio-fees",
    path: portfolio_45fees16m8y1Rhu8Meta?.path ?? "/portfolio-fees",
    meta: portfolio_45fees16m8y1Rhu8Meta || {},
    alias: portfolio_45fees16m8y1Rhu8Meta?.alias || [],
    redirect: portfolio_45fees16m8y1Rhu8Meta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-fees.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45investorsLpUaAPOHVuMeta?.name ?? "portfolio-investors",
    path: portfolio_45investorsLpUaAPOHVuMeta?.path ?? "/portfolio-investors",
    meta: portfolio_45investorsLpUaAPOHVuMeta || {},
    alias: portfolio_45investorsLpUaAPOHVuMeta?.alias || [],
    redirect: portfolio_45investorsLpUaAPOHVuMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-investors.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45issuerseNzbFaRcf4Meta?.name ?? "portfolio-issuers",
    path: portfolio_45issuerseNzbFaRcf4Meta?.path ?? "/portfolio-issuers",
    meta: portfolio_45issuerseNzbFaRcf4Meta || {},
    alias: portfolio_45issuerseNzbFaRcf4Meta?.alias || [],
    redirect: portfolio_45issuerseNzbFaRcf4Meta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-issuers.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45membersfRuKjMuPKVMeta?.name ?? "portfolio-members",
    path: portfolio_45membersfRuKjMuPKVMeta?.path ?? "/portfolio-members",
    meta: portfolio_45membersfRuKjMuPKVMeta || {},
    alias: portfolio_45membersfRuKjMuPKVMeta?.alias || [],
    redirect: portfolio_45membersfRuKjMuPKVMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-members.vue").then(m => m.default || m)
  },
  {
    name: indexDR4GABt9wpMeta?.name ?? "portfolio-news",
    path: indexDR4GABt9wpMeta?.path ?? "/portfolio-news",
    meta: indexDR4GABt9wpMeta || {},
    alias: indexDR4GABt9wpMeta?.alias || [],
    redirect: indexDR4GABt9wpMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/index.vue").then(m => m.default || m)
  },
  {
    name: transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta?.name ?? "portfolio-news-transformation-of-an-edav-into-a-reit",
    path: transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta?.path ?? "/portfolio-news/transformation-of-an-edav-into-a-reit",
    meta: transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta || {},
    alias: transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta?.alias || [],
    redirect: transformation_45of_45an_45edav_45into_45a_45reitfgV2ud1buhMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-news/transformation-of-an-edav-into-a-reit.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45partnersqDGmtoktaLMeta?.name ?? "portfolio-partners",
    path: portfolio_45partnersqDGmtoktaLMeta?.path ?? "/portfolio-partners",
    meta: portfolio_45partnersqDGmtoktaLMeta || {},
    alias: portfolio_45partnersqDGmtoktaLMeta?.alias || [],
    redirect: portfolio_45partnersqDGmtoktaLMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/portfolio-partners.vue").then(m => m.default || m)
  },
  {
    name: privacy12YVewrbhgMeta?.name ?? "privacy",
    path: privacy12YVewrbhgMeta?.path ?? "/privacy",
    meta: privacy12YVewrbhgMeta || {},
    alias: privacy12YVewrbhgMeta?.alias || [],
    redirect: privacy12YVewrbhgMeta?.redirect,
    component: () => import("/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/pages/privacy.vue").then(m => m.default || m)
  }
]