import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import gsapPlugin_HlwIIyl85L from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/.nuxt/gsapPlugin.mjs";
import titles_m9SjywKgWm from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_yfWm7jX06p from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import html2pdf_client_CnElePg9y5 from "/codebuild/output/src1350084818/src/codestar-connections.eu-west-1.amazonaws.com/git-http/171566923596/eu-west-1/ead33cad-428e-4c16-b3bf-4484b37c614e/portfolio-exchange/poex-corporate-web/plugins/html2pdf.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  gsapPlugin_HlwIIyl85L,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  html2pdf_client_CnElePg9y5
]